import { useState, useEffect } from "react";
import { loginRequest } from "../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import nttIcon from "../assets/logo_nttdata.png";
import googleIcon from "../assets/google_logo.jpg";
import "../Styles/Login.css";

export const MsalBtn = () => {
  const { instance } = useMsal();
  const isAuthenticated = useIsAuthenticated();
  const [localAccountId, setLocalAccountId] = useState("");

  if (
    sessionStorage.getItem("invalidGroup") ||
    sessionStorage.getItem("sessionClosed")
  ) {
    sessionStorage.clear();
    instance.logoutRedirect(loginRequest);
  }

  const handleLogin = async () => {
    if (sessionStorage.getItem("logged")) {
      window.location.href = "/tienda/categorias";
    } else {
      sessionStorage.clear();
      instance.loginRedirect(loginRequest).catch((e) => {});
      await instance.handleRedirectPromise();
    }
  };

  const handleSingnIn = async () => {
    const accounts = instance.getAllAccounts();

    if (accounts.length > 0) {
      setLocalAccountId(accounts[0]?.localAccountId);
    }

    await instance.handleRedirectPromise();

    if (!sessionStorage.getItem("logout")) {
      const userInfoObject = JSON.parse(
        sessionStorage.getItem(
          localAccountId +
            ".3048dc87-43f0-4100-9acb-ae1971c79395-login.windows.net-3048dc87-43f0-4100-9acb-ae1971c79395"
        )
      );
      const accesstokenObject = JSON.parse(
        sessionStorage.getItem(
          localAccountId +
            ".3048dc87-43f0-4100-9acb-ae1971c79395-login.windows.net-accesstoken-25dc2e1a-eced-46ab-8e36-dd230ddfdbd8-3048dc87-43f0-4100-9acb-ae1971c79395-openid profile user.read email--"
        )
      );
      sessionStorage.setItem("name", userInfoObject?.name);
      sessionStorage.setItem("token", accesstokenObject?.secret);
      sessionStorage.setItem("logged", "true");
      window.location.href = "/tienda/categorias";
    }
  };

  if (isAuthenticated && !sessionStorage.getItem("logged")) {
    handleSingnIn();
  }

  const handleLogout = (instance) => {
    sessionStorage.clear();
    sessionStorage.setItem("logout", "true");
    const logoutRequest = {
      account: instance.getAccountByHomeId(localAccountId),
      postLogoutRedirectUri: "http://localhost:9100",
    };
    instance.logoutRedirect(logoutRequest);
  };
  let userName = sessionStorage.getItem("name");
  return (
    <>
      <button onClick={handleLogin} className="login-form-btn btn-ntt">
        {isAuthenticated ? (
          <span> Continuar como {userName}</span>
        ) : (
          <>
            <img src={nttIcon} alt="" />
            <span>Ingreso corporativo</span>
          </>
        )}
      </button>
      {isAuthenticated && (
        <button
          onClick={() => handleLogout(instance)}
          className="login-form-btn"
        >
          Cerrar sesión
        </button>
      )}
    </>
  );
};
