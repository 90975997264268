import Config from "../environments/config.json";
export const msalConfig = {
  auth: {
    clientId: "3cd0261f-11ed-49c0-aa71-4bdad29129ec",
    tenant:
      "https://login.microsoftonline.com/9dbc76ea-fb25-4b07-8f07-5dc315999b76",
    redirectUri: Config.PUBLIC_TERPEL_REDIRECT_URL,
    postLogoutRedirectUri: Config.PUBLIC_TERPEL_REDIRECT_URL,
  },
  cache: {
    cacheLocation: "sessionStorage", // This configures where your cache will be stored
    storeAuthStateInCookie: false, // Set this to "true" if you are having issues on IE11 or Edge
  },
};

// Add scopes here for ID token to be used at Microsoft identity platform endpoints.
export const loginRequest = {
  scopes: ["user.read"],
};

// Add the endpoints here for Microsoft Graph API services you'd like to use.
// export const graphConfig = {
// 	graphMeEndpoint: 'Enter_the_Graph_Endpoint_Here/v1.0/me',
// };
