import { useState, useEffect } from "react";
import { loginRequest } from "../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import nttIcon from "../assets/logo_nttdata.png";
import googleIcon from "../assets/google_logo.jpg";
import "../Styles/Login.css";

export const GoogleBtn = () => {
  return (
    <>
      <button className="login-form-btn btn-google">
        <img src={googleIcon} alt="" />
        Ingresar con Google
      </button>
    </>
  );
};
