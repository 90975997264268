import { useState, useEffect } from "react";
import { loginRequest } from "../authConfig";
import { useMsal, useIsAuthenticated } from "@azure/msal-react";
import nttIcon from "../assets/logo_nttdata.png";
import googleIcon from "../assets/google_logo.jpg";
import "../Styles/Login.css";
import { MsalBtn } from "./MsalBtn";
import { GoogleBtn } from "./GoogleBtn";

export const Login = () => {
  return (
    <>
      <div className="other-buttons-container">
        <GoogleBtn />
        <MsalBtn />
      </div>
    </>
  );
};
